<template>
  <div class="login-page">
    <div class="notice-box">
      <p class="warning-title">注意：</p>
      <p class="warning-content">禁止多登，多登有售后不负责</p>
      <p class="warning-content">请勿绑定任何个人信息</p>
      <p class="warning-content"> 私下绑定出现任何问题</p>
      <p class="warning-content">  与本店无关</p>
    </div>

    <!-- 扫码功能按钮 -->
    <div class="buttons">
      <button @click="startScanning" class="scan-btn" v-if="!scanning">点击此处扫描二维码登录</button>
    </div>

    <!-- 当点击按钮并开始扫描后，展示摄像头扫码区域 -->
    <div class="scanner" v-if="scanning">
      <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
    </div>

    <!-- 上传二维码图片按钮 -->
    <div class="buttons">
      <button @click="triggerFileUploadAndCloseCamera" class="upload-btn">点击此处上传二维码登录</button>
      <!-- 隐藏的文件上传输入框 -->
      <input type="file" ref="fileInput" accept="image/*" @change="onFileChange" style="display: none;" />
    </div>

    <div class="instructions">
      <p>登录失败 试试用电脑浏览器打开 上传二维码登录</p>
      <p>登录失败 等2分钟 再扫一次 间隔几分钟扫几次</p>
    </div>

    <div class="timing-info">
         <p>
        卡密激活时间: 
        <span :class="{'error-text': isError, 'success-text': !isError}">{{ activationTime }}</span>
    </p>
      <p>卡密到期时间:{{ expireTime }}</p>
    </div>

    <button @click="openContactModalAndCloseCamera" class="contact-btn">联系客服</button>

    <!-- 显示扫码或上传结果 -->
    <p v-if="resultMessage" class="result-message">{{ resultMessage }}</p>

    <!-- 隐藏的 canvas 元素，用于绘制上传的图片 -->
    <canvas ref="canvas" style="display: none;"></canvas>

    <!-- 联系客服模态弹窗 -->
    <div v-if="showContactModal" class="modal">
      <div class="modal-content">
        <span class="close-btn" @click="closeContactModal">&times;</span>
        <h2>联系客服</h2>
        <p>请扫描以下二维码联系客服：</p>
        <img src="@/assets/images/kf1.png" alt="客服二维码" class="qr-code-img" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import jsQR from 'jsqr';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      resultMessage: null,  
      scanning: false,  
      showContactModal: false,  
      sign: null, 
      activationTime: '',
      expireTime: '',
    };
  },
  mounted() {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    this.sign = urlParams.get('sign');
      axios.post('/api/sq_cx', {
        sign: this.sign,
      })
    .then(response => {
      if (response.data.msg === "查询成功") {
        this.activationTime = response.data.首次时间;
        this.expireTime = response.data.到期时间;
      } else {
        this.activationTime = response.data.msg;
          this.isError = true;
      }
    })
    .catch(error => {
      console.error("请求失败:", error);
      this.activationTime = "未查询到时间";
          this.isError = true;
    });
  },
  methods: {
    startScanning() {
      this.scanning = true;
    },
    onDecode(decodedString) {
     const urlObj = new URL(decodedString);
const redirectUri = urlObj.searchParams.get('redirect_uri');
let id;
if (redirectUri) {
   const  redirectUrlObj = new URL(redirectUri);
 id = redirectUrlObj.searchParams.get('user_code');
} else {
    id = urlObj.searchParams.get('user_code');
}
      axios.post('/api/sq_sm', {
        sign: this.sign,
        id: id
      })
      .then(response => {
        if (response.data.msg === "扫码成功") {
           this.activationTime = response.data.首次时间; 
           this.expireTime = response.data.到期时间; 
           this.isError = false; 
        } else {
          this.activationTime = response.data.msg;
          this.isError = true;
        }
        this.scanning = false;
      })
      .catch(error => {
        console.error(error);
        this.resultMessage = "请求失败, 请稍后再试";
      });
    },
    onInit(promise) {
      promise.catch(error => {
        console.error("摄像头初始化失败:", error);
        this.resultMessage = "摄像头初始化失败: " + error.message;
        this.scanning = false;
      });
    },
    triggerFileUploadAndCloseCamera() {
      this.scanning = false;
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.decodeQRCodeFromImage(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
   decodeQRCodeFromImage(imageData) {
  const img = new Image();
  img.src = imageData;
  img.onload = () => {
    const canvas = this.$refs.canvas;
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);
    
    try {
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, canvas.width, canvas.height);
      if (code) {
        this.onDecode(code.data);
      } else {
        console.warn("解析二维码失败，尝试生成的二维码内容：", imageData.data);
        this.resultMessage = "无法解析二维码, 请检查二维码的清晰度或格式";
      }
    } catch (error) {
      console.error("获取图像数据失败:", error);
      this.resultMessage = "获取图像数据失败，请检查图像格式";
    }
  };
  img.onerror = () => {
    this.resultMessage = "图片加载失败，请重新选择文件";
  };
},
    openContactModalAndCloseCamera() {
      this.scanning = false;
      this.showContactModal = true;
    },
    closeContactModal() {
      this.showContactModal = false;
    }
  }
}

</script>

<style scoped>
.login-page {
  width: 100%;
  height: calc(100vh - 40px); /* 确保内容不超出视图高度 */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Arial, sans-serif;
  overflow-y: auto; /* 添加垂直滚动条 */
  padding: 10px;
}

.notice-box {
  text-align: center;
  margin-bottom: 20px;
}

.warning-title {
  font-weight: bold;
  font-size: 24px;
  color: #d32f2f;
  margin-bottom: 10px;
}

.warning-content {
  font-size: 18px;
  color: #d32f2f;
  margin-bottom: 10px;
}

.scanner {
  margin: 20px 0;
}

.file-upload {
  margin: 20px 0;
}

button {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.scan-btn {
  background: linear-gradient(45deg, #4caf50, #81c784);
  color: white;
}

.scan-btn:hover {
  background: linear-gradient(45deg, #388e3c, #66bb6a);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.upload-btn {
  background: linear-gradient(45deg, #2196f3, #64b5f6);
  color: white;
}

.upload-btn:hover {
  background: linear-gradient(45deg, #1976d2, #42a5f5);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.contact-btn {
  background: linear-gradient(45deg, #f44336, #e57373);
  color: white;
  width: 30%;
}

.contact-btn:hover {
  background: linear-gradient(45deg, #d32f2f, #ef5350);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.instructions {
  font-size: 16px;
  color: gray;
  margin-bottom: 5px;
}

.timing-info {
  font-size: 18px;
  color: black;
  margin-bottom: 5px;
  padding: 2px 0;
}

.result-message {
  font-size: 18px;
  color: red;
  margin-top: 20px;
}
.success-text {
 color: black; /* 正常显示黑色 */
}

.error-text {
  color: red; /* 错误显示红色 */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-y: auto; /* 模态框内容过多时滚动 */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}

.qr-code-img {
  width: 100%;
  height: auto;
  margin-top: 15px;
}

</style>
